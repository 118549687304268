import { AlertProps } from '@mui/material';
import moment, { Moment } from 'moment';
import { createSelector } from 'reselect';
import logDefaultCaseWarningReducer from './stateHelper';

const intervalSecondsMaintenance = 30;

export const POLLING_INTERVAL = intervalSecondsMaintenance * 1000;

export type MaintenanceState = {
  pending: boolean;
  currentDate: Moment;
  cacheBusterDate: Moment;
  notificationMessage: string;
  notificationMessageTitle: string;
  notificationMessageSeverity?: AlertProps['severity'];
  inMaintenance?: boolean;
  maintenanceMessage: string;
  scheduledDate?: string | null;
  status?: 'FULL' | 'SCHEDULED';
};

export const defaultMaintenanceState: MaintenanceState = {
  pending: true, // guard that is used before the maintenance.json is fetched
  currentDate: moment.utc(),
  cacheBusterDate: moment.utc(),
  notificationMessage: '',
  notificationMessageTitle: '',
  inMaintenance: undefined,
  maintenanceMessage: '',
  scheduledDate: null,
  status: undefined,
};

export const inputTypes = {
  updateMaintenance: 'updateMaintenance',
  updateTime: 'updateTime',
  updateCacheBusterTime: 'updateCacheBusterTime',
} as const;
export type MaintenanceDispatchActions = typeof inputTypes;

export type MaintenanceStateReducerInput =
  | {
      type: MaintenanceDispatchActions['updateMaintenance'];
      payload: Partial<MaintenanceState>;
    }
  | {
      type: MaintenanceDispatchActions['updateTime'];
      payload: Moment;
    }
  | {
      type: MaintenanceDispatchActions['updateCacheBusterTime'];
      payload: Moment;
    };

export const maintenanceReducer = (
  state: MaintenanceState,
  { type, payload }: MaintenanceStateReducerInput
): MaintenanceState => {
  switch (type) {
    case 'updateMaintenance':
      return {
        ...state,
        ...payload,
        notificationMessageSeverity:
          payload.notificationMessageSeverity &&
          ['success', 'info', 'warning', 'error'].includes(
            payload.notificationMessageSeverity
          )
            ? payload.notificationMessageSeverity
            : 'warning',
        pending: false,
      };
    case 'updateTime':
      return { ...state, currentDate: payload };
    case 'updateCacheBusterTime':
      return { ...state, cacheBusterDate: payload };

    default:
      logDefaultCaseWarningReducer('maintenanceState', type, payload);
      return state;
  }
};

export const isInMaintenanceSelector = createSelector(
  (state: MaintenanceState) => state.inMaintenance,
  (state: MaintenanceState) => state.status,
  (inMaintenance, status) =>
    inMaintenance !== undefined &&
    typeof inMaintenance === 'boolean' &&
    inMaintenance &&
    status === 'FULL'
);

export const pollingIntervalSelector = createSelector(
  () => intervalSecondsMaintenance * 1000
);

export const cacheBusterStringSelector = createSelector(
  (state: MaintenanceState) => state.cacheBusterDate,
  (cacheBusterDate) => cacheBusterDate.clone().seconds(0).unix()
);

export const timeUntilInMaintenanceSelector = createSelector(
  (state: MaintenanceState) => state.scheduledDate,
  (state: MaintenanceState) => state.currentDate,
  (scheduledDate, currentDate) => {
    if (scheduledDate !== undefined && typeof scheduledDate === 'string') {
      const inMaintenanceDate = moment(scheduledDate).utc();

      if (
        inMaintenanceDate.isValid() &&
        currentDate.isBefore(inMaintenanceDate)
      ) {
        const duration = moment.duration(inMaintenanceDate.diff(currentDate));

        const totalDays = Math.floor(duration.days() || 0);
        const weeks = Math.floor(totalDays / 7);
        const days = totalDays % 7;

        return {
          seconds: duration.seconds(),
          minutes: duration.minutes(),
          hours: duration.hours(),
          days,
          weeks,
          months: duration.months(),
          years: duration.years(),
        };
      }
      return;
    }

    return;
  }
);
